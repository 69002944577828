import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
	EnvironmentConfig,
	ENV_CONFIG
} from "@core/environments/environment-config";
import { Permission } from "@shared/schemas/permission";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
	providedIn: "root"
})
export class PermissionService {
	API_URL = "";
	constructor(
		private http: HttpClient,
		private authService: AuthService,
		@Inject(ENV_CONFIG) private config: EnvironmentConfig
	) {
		this.API_URL = `${config.environment.API}`;
	}

	loadPermissionsV2(): Observable<Permission> {
		return this.http.get<Permission>(
			`${
				this.config.environment.API_BFF
			}/auth/load-permissions?hashedFingerprint=${
				this.authService.getHashedFingerprint() ?? ""
			}&idUnit=${
				this.authService.getUserLogin()?.idUnidadePersistencia ?? ""
			}`,
			{ withCredentials: true }
		);
	}
}
