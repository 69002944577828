<li
	(click)="emitActualMenu()"
	class="nav-item"
	[class.icon-menu-mobile]="!sidebarExpanded"
	*ngxPermissionsOnly="permissions"
>
	<div *ngIf="!submenus.length">
		<a
			[class.active-parent-link]="router.isActive(menuId, false)"
			class="nav-link px-0 menu-link main-menu"
			[routerLink]="[menuId]"
			(click)="closeMobileSidebarMenu(menuName)"
			#menu
		>
			<div class="main-menu-content">
				<span
					[class.active-parent-icon]="router.isActive(menuId, false)"
					class="{{ menuClassIcon }} fs-4 ps-5 ms-1"
				></span>
				<span
					class="show-content noselect"
					[class.show-menu-animation]="sidebarExpanded"
					[class.hide-menu-animation]="!sidebarExpanded"
				>
					{{ menuName }}
				</span>
			</div>
		</a>
	</div>
	<div *ngIf="submenus.length">
		<a
			[class.active-parent-link]="router.isActive(menuId, false)"
			class="nav-link px-0 menu-link main-menu"
			data-bs-toggle="collapse"
			[attr.data-bs-target]="'#' + menuId"
			aria-expanded="false"
			#menu
		>
			<div class="main-menu-content">
				<span
					[class.active-parent-icon]="router.isActive(menuId, false)"
					class="{{ menuClassIcon }} fs-4 ps-5 ms-1"
				></span>
				<ng-container>
					<span
						class="show-content noselect"
						[class.show-menu-animation]="sidebarExpanded"
						[class.hide-menu-animation]="!sidebarExpanded"
					>
						{{ menuName }}
					</span>
					<span
						class="d-inline-block arrow-icon"
						[class.show-menu-animation]="sidebarExpanded"
						[class.hide-menu-animation]="!sidebarExpanded"
					></span>
					@if (
						showNewsHotspot &&
						sidebarExpanded &&
						hotspotNewsWasClicked &&
						isNewAccountingAccount
					) {
						<atlas-hotspot></atlas-hotspot>
					}
				</ng-container>
			</div>
		</a>
		<div
			class="collapse"
			[id]="menuId"
			[class.d-none]="!sidebarExpanded"
			#submenu
		>
			<ul class="list-unstyled ms-5 list-main-menu">
				<li class="nav-item" *ngFor="let submenu of submenus">
					<div *ngIf="!submenu.hasChildren && !submenu.openNewTab">
						<a
							routerLinkActive="is-active-menu"
							*ngxPermissionsOnly="submenu.permissionName"
							[routerLink]="[menuId + '/' + submenu.routerLink]"
							class="anchor nav-link noselect link-submenu"
							(click)="handleSubmenuClick(submenu)"
						>
							{{ submenu.menuName | translate }}
							@if (submenu.showNewBadge && isClicked) {
								<atlas-hotspot></atlas-hotspot>
							}
						</a>
					</div>
					<div *ngIf="submenu.openNewTab">
						<a
							*ngxPermissionsOnly="submenu.permissionName"
							[href]="submenu.jsfUrlPage"
							target="_blank"
							class="nav-link noselect link-submenu"
							rel="noopener noreferrer"
						>
							{{ submenu.menuName | translate }}
						</a>
					</div>
					<div *ngIf="submenu.hasChildren">
						<a
							*ngxPermissionsOnly="submenu.permissionName"
							href="#"
							class="nav-link pe-0 menu-link submenu-link"
							data-bs-toggle="collapse"
							[class.active-parent-icon]="
								router.isActive(
									menuId + '/' + submenu.routerLink,
									false
								)
							"
							[attr.data-bs-target]="'#' + submenu.id"
							aria-expanded="false"
							(click)="handleSubmenuClick(submenu)"
						>
							<span
								[class.active-parent-icon]="
									router.isActive(
										menuId + '/' + submenu.routerLink,
										false
									)
								"
								class="{{ submenu.menuClassIcon }} fs-4 ps-5"
							></span>
							<span
								class="noselect"
								[ngClass]="{
									'd-none': !sidebarExpanded,
									'd-inline': sidebarExpanded
								}"
							>
								{{ submenu.menuName | translate }}
							</span>
							<span
								class="arrow-icon"
								[ngClass]="{
									'd-none': !sidebarExpanded,
									'd-inline-block': sidebarExpanded
								}"
							></span>
							@if (
								submenu.showNewBadge &&
								hotspotNewsWasClicked &&
								isNewAccountingAccount
							) {
								<atlas-hotspot></atlas-hotspot>
							}
						</a>
						<div
							class="collapse"
							[id]="submenu.id"
							[class.d-none]="!sidebarExpanded"
						>
							<ul class="list-unstyled ms-2 list-submenu">
								<li
									class="nav-item"
									*ngFor="
										let submenuChild of submenu.children
									"
								>
									<a
										*ngxPermissionsOnly="
											submenuChild.permissionName
										"
										routerLinkActive="is-active-menu"
										[routerLink]="[
											menuId +
												'/' +
												submenu.routerLink +
												'/' +
												submenuChild.routerLink
										]"
										class="nav-link noselect"
										(click)="
											closeMobileSidebarMenu(
												submenuChild.menuName
											);
											handleHotstopNewsClick(submenuChild)
										"
									>
										{{ submenuChild.menuName | translate }}
										@if (
											submenuChild.showNewBadge &&
											hotspotNewsWasClicked &&
											isNewAccountingAccount
										) {
											<atlas-hotspot></atlas-hotspot>
										}
									</a>
								</li>
							</ul>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</li>
