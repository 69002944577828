<div
	class="px-0"
	[ngClass]="sidebarExpanded ? 'sidebarExpanded' : 'sidebarClosed'"
	[class.sidebar-wrapper]="!isMobile"
	[class.sidebar-wrapper-mobile]="isMobile"
	(mouseleave)="hideSidebar()"
>
	<div
		class="sidebar-content"
		[class.min-vh-100]="!isMobile"
		[class.pt-2]="!isMobile"
	>
		<a [routerLink]="['/home']">
			<div class="logo-image"></div>
		</a>

		@if (quickAccessPermissions === "CREATE_COMMERCIAL_PROPOSAL") {
			<ng-container
				*ngTemplateOutlet="buttonCreateBusinessProposal"
			></ng-container>
		}

		@if (quickAccessPermissions === "CREATE_RECEIPT") {
			<ng-container
				*ngTemplateOutlet="buttonCreateReceipt"
			></ng-container>
		}

		@if (quickAccessPermissions === "BOTH") {
			<ng-container
				*ngTemplateOutlet="dropdownQuickAccessCreate"
			></ng-container>
		}

		<div class="sidebar-menu-list" (mouseover)="showSidebar()">
			<ng-scrollbar>
				<ul class="nav nav-pills flex-column mb-auto mt-3">
					<app-sidebar-menu
						[menuName]="'SIDEBAR.DASHBOARD.TITLE' | translate"
						menuId="home"
						[permissions]="['INDICADORESFINANCEIROS']"
						menuClassIcon="menu-dashboard-icon"
						[sidebarExpanded]="sidebarExpanded"
						(closeMobileSidebar)="closeMobileSidebarMenu($event)"
					></app-sidebar-menu>
					<app-sidebar-menu
						[menuName]="'SIDEBAR.COMMERCIAL.TITLE' | translate"
						menuId="comercial"
						[permissions]="['COMERCIAL']"
						menuClassIcon="menu-comercial-icon"
						[submenus]="submenusComercial"
						[sidebarExpanded]="sidebarExpanded"
						(closeMobileSidebar)="closeMobileSidebarMenu($event)"
					></app-sidebar-menu>
					<app-sidebar-menu
						[menuName]="'SIDEBAR.SERVICES.TITLE' | translate"
						menuId="servicos"
						menuClassIcon="menu-servicos-icon"
						[permissions]="['SERVICOS']"
						[submenus]="submenusServicos"
						[sidebarExpanded]="sidebarExpanded"
						(closeMobileSidebar)="closeMobileSidebarMenu($event)"
					></app-sidebar-menu>
					<app-sidebar-menu
						[menuName]="'SIDEBAR.REVENUES.TITLE' | translate"
						menuId="faturamento"
						[permissions]="['FATURAMENTO']"
						menuClassIcon="menu-faturamento-icon"
						[submenus]="submenusFaturamento"
						[sidebarExpanded]="sidebarExpanded"
						(closeMobileSidebar)="closeMobileSidebarMenu($event)"
					></app-sidebar-menu>
					<app-sidebar-menu
						[menuName]="'SIDEBAR.FINANCIAL.TITLE' | translate"
						menuId="financeiro"
						[permissions]="['FINANCEIRO']"
						menuClassIcon="menu-financeiro-icon"
						[submenus]="submenusFinanceiro"
						[sidebarExpanded]="sidebarExpanded"
						(closeMobileSidebar)="closeMobileSidebarMenu($event)"
					></app-sidebar-menu>
					<app-sidebar-menu
						[menuName]="'SIDEBAR.POINT_OF_SALE.TITLE' | translate"
						menuId="ponto-de-venda"
						menuClassIcon="menu-ponto-de-venda-icon"
						[permissions]="['PONTODEVENDA']"
						[submenus]="submenusPontoVenda"
						[sidebarExpanded]="sidebarExpanded"
						(closeMobileSidebar)="closeMobileSidebarMenu($event)"
					></app-sidebar-menu>
					<app-sidebar-menu
						[menuName]="'SIDEBAR.PRODUCTION.TITLE' | translate"
						menuId="producao"
						menuClassIcon="menu-producao-icon"
						[permissions]="['PRODUCAO']"
						[submenus]="submenusProducao"
						[sidebarExpanded]="sidebarExpanded"
						(closeMobileSidebar)="closeMobileSidebarMenu($event)"
					></app-sidebar-menu>
					<app-sidebar-menu
						[menuName]="'SIDEBAR.SUPPLIES.TITLE' | translate"
						menuId="suprimentos"
						menuClassIcon="menu-suprimentos-icon"
						[permissions]="['SUPRIMENTOS']"
						[submenus]="submenusSuprimentos"
						[sidebarExpanded]="sidebarExpanded"
						(closeMobileSidebar)="closeMobileSidebarMenu($event)"
					></app-sidebar-menu>
					<app-sidebar-menu
						[menuName]="'SIDEBAR.ACCOUNTING.TITLE' | translate"
						menuId="contabilidade"
						menuClassIcon="menu-contabilidade-icon"
						[permissions]="['CONTABILIDADEONLINE']"
						[submenus]="submenusContabilidadeOnline"
						[sidebarExpanded]="sidebarExpanded"
						(closeMobileSidebar)="closeMobileSidebarMenu($event)"
					></app-sidebar-menu>
					<app-sidebar-menu
						[menuName]="'SIDEBAR.REPORTS.TITLE' | translate"
						menuId="relatorios"
						[permissions]="['RELRELATORIOS']"
						menuClassIcon="menu-relatorios-icon"
						[submenus]="submenusRelatorios"
						[showNewsHotspot]="true"
						[sidebarExpanded]="sidebarExpanded"
						(closeMobileSidebar)="closeMobileSidebarMenu($event)"
					></app-sidebar-menu>
				</ul>
			</ng-scrollbar>
		</div>
	</div>
	<div
		class="expand-button"
		(click)="toggleSidebar()"
		[ngClass]="sidebarPinned ? 'arrow-close-sidebar' : 'arrow-open-sidebar'"
	></div>
</div>

<ng-template #buttonCreateBusinessProposal>
	<div class="d-grid px-3">
		<atlas-button
			data-testid="create-receipt-button"
			type="filled"
			theme="primary"
			block=""
			[attr.pill]="!sidebarExpanded ? '' : undefined"
			[attr.icon]="!sidebarExpanded ? 'plus' : undefined"
			[attr.size]="sidebarExpanded ? 'md' : 'lg'"
			[attr.description]="
				sidebarExpanded
					? ('SIDEBAR.COMMERCIAL.CREATE_BUSINESS_PROPOSAL'
						| translate)
					: undefined
			"
			[ngClass]="sidebarExpanded ? 'w-100' : ''"
			type="filled"
			(click)="openModal('proposta_comercial')"
		>
		</atlas-button>
	</div>
</ng-template>

<ng-template #buttonCreateReceipt>
	<div class="d-grid px-3">
		<atlas-button
			data-testid="create-receipt-button"
			type="filled"
			theme="primary"
			block=""
			[attr.pill]="!sidebarExpanded ? '' : undefined"
			[attr.icon]="!sidebarExpanded ? 'plus' : undefined"
			[attr.size]="sidebarExpanded ? 'md' : 'lg'"
			[attr.description]="
				sidebarExpanded
					? ('SIDEBAR.FINANCIAL.CREATE_RECEIPT' | translate)
					: undefined
			"
			[ngClass]="sidebarExpanded ? 'w-100' : ''"
			type="filled"
			(click)="openWizard()"
		>
		</atlas-button>
	</div>
</ng-template>

<ng-template #dropdownQuickAccessCreate>
	<div class="d-grid px-3">
		<atlas-dropdown-button
			data-testid="mobile-btn"
			type="filled"
			theme="primary"
			block=""
			[ngClass]="sidebarExpanded ? 'w-100' : ''"
			[attr.size]="sidebarExpanded ? 'md' : 'lg'"
			[attr.pill]="!sidebarExpanded ? '' : undefined"
			[attr.hide-arrow]="!sidebarExpanded ? '' : undefined"
			[attr.icon]="!sidebarExpanded ? 'plus' : undefined"
			[attr.description]="
				sidebarExpanded ? ('SIDEBAR.CREATE' | translate) : undefined
			"
		>
			<atlas-dropdown-item (click)="openModal('proposta_comercial')">
				{{ "SIDEBAR.COMMERCIAL.CREATE_BUSINESS_PROPOSAL" | translate }}
			</atlas-dropdown-item>
			<atlas-dropdown-item (click)="openWizard()">
				{{ "SIDEBAR.FINANCIAL.CREATE_RECEIPT" | translate }}
			</atlas-dropdown-item>
		</atlas-dropdown-button>
	</div>
</ng-template>
