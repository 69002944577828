import { Injectable } from "@angular/core";
import { LocalStorageService } from "./storage/local-storage.service";
import { AuthService } from "./auth/auth.service";
import { LoginResponse } from "@shared/schemas/complete-login";

interface ConversationFields {
	id: string;
	value: string | number | boolean;
}
type JwtFn = (jwtFn: (jwt: string) => void) => unknown;
interface ZendeskWindow extends Window {
	zE: (
		type: string,
		action: string,
		param?: JwtFn | ConversationFields[]
	) => void;
}

@Injectable({
	providedIn: "root"
})
export class ZendeskService {
	constructor(
		private localStorageService: LocalStorageService,
		private window: Window,
		private authService: AuthService
	) {}

	initZendesk(): void {
		const zendeskJwt = this.localStorageService.get<string>("jwtZendesk");
		const userLogin =
			this.localStorageService.get<LoginResponse>("userLogin");
		if (zendeskJwt) {
			const zendeskWindow = this.window as ZendeskWindow;
			zendeskWindow.zE("messenger", "show");
			zendeskWindow.zE(
				"messenger",
				"loginUser",
				(callback: (jwt: string) => void) => {
					callback(zendeskJwt);
				}
			);
			const cf = [
				{
					id: "34882307616923",
					value: userLogin?.email
				} as ConversationFields
			];
			zendeskWindow.zE("messenger:set", "conversationFields", cf);
		}
	}

	hideZendesk(): void {
		const zendeskWindow = this.window as ZendeskWindow;
		zendeskWindow.zE("messenger", "hide");
	}
}
